@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Delius&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.dm-serif-text-regular {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}

.dm-serif-text-regular-italic {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: italic;
}
.delius-regular {
  font-family: "Delius", cursive;
  font-weight: 400;
  font-style: normal;
}


:root{
  font-family: "DM Serif Text", serif;
}